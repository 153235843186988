<template>
  <v-row align="center" justify="center">
    <v-col cols="6">
      <v-card class="elevation-12">
        <v-card-text>
          <v-form ref="form">
            <input type="hidden" v-model="fs_kd_peg">
            <center>
             <v-avatar size="128" class="mx-auto">
              <v-icon x-large v-if="foto_profil_baru=='' || foto_profil_baru==null" >mdi-account-circle</v-icon>
              <v-img v-else :src="foto_profil_baru"></v-img>
            </v-avatar>
            <input type="file" accept="image/jpeg,image/png" @change="onFileChange" />
            </center>
            <v-text-field
              label="Nama"
              type="text"
              v-model="nama_lengkap"
              readonly
            ></v-text-field>
            <v-text-field
              label="No HP"
              type="text"
              v-model="no_hp"
              readonly
            ></v-text-field>
            <v-text-field
              label="Instansi"
              v-model="karyawan"
              type="text"
              readonly
            ></v-text-field>
            
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="simpan">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
      fs_kd_peg: '',
      nama_lengkap: "",
      no_hp: "",
      karyawan: "",
      foto_profil_lama: "",
      foto_profil_baru: "",
      foto_profil_baru_file: ""
    };
  },
  created() {
    this.fs_kd_peg= this.$store.getters.user.fs_kd_peg
    this.nama_lengkap= this.$store.getters.user.nama_lengkap
    this.no_hp= this.$store.getters.user.no_hp
    this.karyawan= this.$store.getters.user.karyawan
    this.foto_profil_lama= this.$store.getters.user.foto_profil
    this.foto_profil_baru= this.$store.getters.user.foto_profil_full
  },
  computed: {
    user(){
      return this.$store.getters.user;
    },
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      // this.url = URL.createObjectURL(file);
      if (file.type == 'image/jpeg' || file.type == 'image/png') {
        this.foto_profil_baru=  URL.createObjectURL(file)
        this.foto_profil_baru_file = file;
      }
      else{
        alert('format file tidak sesuai')
      }
    },
    simpan() {
      var data = new FormData()
      data.append('file_lama', this.foto_profil_lama)
      data.append('file', this.foto_profil_baru_file)
      this.$store.dispatch("simpanProfil",data);
    },
  },
};
</script>
